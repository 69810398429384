import React, { useState } from 'react';
import Modal from 'react-modal';
import './SalesHero.css';
import { ReactComponent as TryUsNow } from './icons/TryUsNow.svg';
import girlImage1 from './icons/girl1.png';
import girlImage2 from './icons/girl2.png';

const SalesHero = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({ email: '', name: '', message: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., send data to server)
        console.log(formData);
        setIsModalOpen(false);
    };

    return (
        <div className="sales-hero-container">
            <div className="sales-left-container">
                <div className="sales-title">
                    <span>Your fantasies</span>
                </div>
                <div className="sales-title-2">
                    <span>are waiting.</span>
                </div>
                <h2 className="sales-subtitle">Dream aloud, she's here to listen and lift you up.</h2>
                <button className='join-button' onClick={() => setIsModalOpen(true)}>
                    <TryUsNow />
                    AI Companion
                </button>
            </div>
            <div className="container-bottom-left">
                <img src={girlImage1} alt="Girl 1" />
            </div>
            <div className="container-bottom-right">
                <img src={girlImage2} alt="Girl 2" />
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="AI Companion Form"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <h2>AI Companion Form</h2>
                <p
                style={{marginTop: -20}}
                >Fill out the form below to get started.</p>
                <form onSubmit={handleFormSubmit} className="form">
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Message (optional):</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <button type="submit" className="join-button"
                            style={{marginTop: '0'}}
                    >Submit</button>
                </form>
            </Modal>
        </div>
    );
};

export default SalesHero;
