import React from 'react';
import { ReactComponent as ElyzaLogoIcon } from "./icons/ElyzaLogoFixed.svg";

import './SalesNavbar.css';
import SalesHero from "./SalesHero";
import { Link } from 'react-router-dom';

const SalesNavbar = () => {


    return (

        <nav className="sales-navbar">

            <Link to="/">
                <ElyzaLogoIcon />
            </Link>

            {/*<div className="sales-navbar-buttons">*/}

            {/*    {['Home', 'New Character', 'Contact Us', 'Pricing'].map((section) => (*/}
            {/*        <ScrollLink*/}
            {/*            key={section}*/}
            {/*            to={section}*/}
            {/*            className={`sales-nav-button ${active === section ? 'activeSales' : ''}`}*/}
            {/*            smooth={true}*/}
            {/*            duration={500}*/}
            {/*            spy={true}*/}
            {/*            activeClass="active"*/}
            {/*            onClick={() => handleSetActive(section)}*/}
            {/*        >*/}
            {/*            {section.charAt(0).toUpperCase() + section.slice(1)}*/}
            {/*        </ScrollLink>*/}
            {/*    ))}*/}

            {/*</div>*/}
        </nav>
    );
};

const SalesPage = () => {
    return (
        <div className="first-gradient">
            <>
                <SalesNavbar />
                <SalesHero />
            </>
        </div>
    );
};

export default SalesPage;
