import React from "react";
import './SalesPage.css';
import SalesNavbar from "./sales/SalesNavbar";
import CarouselPage from "./sales/CarouselPage";
import Features from "./sales/Features";
import FrequentQuestions from "./sales/FrequentQuestions";
// import Footer from "./sales/Footer";
import { Element } from 'react-scroll';

export const SalesPage = () => {
    return (
        <div className="background-sales-page">
        <div className="overalls">
            <SalesNavbar/>
            <Element name="showcase">
            <CarouselPage/>
            </Element>

            <div className='features-gradient'>
            <Element name="features">
                <Features />
            </Element>
        </div>


            <div className='frequent-questions-bg'>
            <Element name="faq">
            <FrequentQuestions/>
            </Element>
            </div>

            {/*<Footer/>*/}
        </div>
        </div>
    );
};
