import React from 'react';
import './SuccessScreen.css';
import { ReactComponent as DiscordIcon } from "./icons/discord.svg";  // Correctly import the DiscordIcon

const SuccessScreen = () => {
    const discordLink = "https://discord.gg/fPPbs9UWY2"; // Replace with your Discord invite link

    return (
        <div className="success-screen-container">
            <h2 className="success-screen-title">Success!</h2>
            <p className="success-screen-message">Your information has been submitted. Thank you for joining our beta!</p>

            {/* Discord section */}
            <a href={discordLink} target="_blank" rel="noopener noreferrer" className="discord-link">
                <div className="discord-section">
                    <DiscordIcon className="discord-icon"/>  {/* Display the Discord icon */}
                    <p className="discord-message">Join our Discord while you are waiting.</p>
                </div>
            </a>
        </div>
    );
};

export default SuccessScreen;
