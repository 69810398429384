import React, { useEffect, useState, useCallback } from 'react';
import Slider from 'react-slick';
import './CarouselPage.css'; // Import your CSS file

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const images = [
    { id: 1, src: './imagefix/2.png' },
    // { id: 2, src: './images/2.png' },
    // { id: 3, src: './images/3.png' },
    // { id: 4, src: './images/4.png' },
    // { id: 5, src: './images/5.png' },
    // { id: 6, src: './images/6.png' },
    // { id: 7, src: './images/7.png' },
    // { id: 9, src: './images/9.png' },
    // { id: 10, src: './images/10.png' },
    // { id: 11, src: './images/11.png' },
    // { id: 12, src: './images/12.png' },
    // { id: 13, src: './images/13.png' },
    // { id: 14, src: './images/14.png' },
    // { id: 15, src: './images/15.png' },
    // { id: 16, src: './images/16.png' },
    // { id: 17, src: './images/17.png' },
    // { id: 18, src: './images/18.png' },
    { id: 2, src: './imagefix/6.png' },
    { id: 3, src: './imagefix/8.png' },
    { id: 4, src: './imagefix/7.png' },
    { id: 5, src: './imagefix/9.png'},
    // { id: 6, src: './imagefix/10.png'},
    { id: 7, src: './imagefix/11.png'},
];
const CarouselItem = ({ image, index, currentIndex }) => (
    <div key={image.id} className="carousel-slide">
        <img
            src={require(`${image.src}`)}
            alt={`${index + 1}`}
            className={`carousel-image ${image.size}-carousel-image${(index === currentIndex) ? ' active-image' : ''}`}
        />
    </div>
);

const CarouselPage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const callChange = useCallback((oldIndex, newIndex) => {
        // if (settings.slidesToShow === 5) {
        //     setCurrentIndex(((newIndex + 2) + images.length) % images.length);
        // } else {
        //     setCurrentIndex((newIndex + 1) % images.length);
        // }
        setCurrentIndex(newIndex);
    }, []);


    const settings = {
        beforeChange: (oldIndex, newIndex) => callChange(oldIndex, newIndex),
        dots: true,
centerMode: true,
centerPadding: "20px",
        arrows: false,
       draggable: true,
        pauseOnDotsHover: true,
        infinite: true,
        speed: 500,
slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    // beforeChange: (oldIndex, newIndex) => callChange(oldIndex, newIndex+2),
                    //fix the position of the carousel
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                    // beforeChange: (oldIndex, newIndex) => callChange(oldIndex, newIndex+1),
                }
            },
            {
                breakpoint: 600,
                settings: {
                    swipeToSlide: true,
                    slidesToShow: 1,
                    dots: false,
                    // beforeChange: (oldIndex, newIndex) => callChange(oldIndex, newIndex),
                }
            }
        ]
    };

useEffect(() => {
    // Preload images to prevent any flickering or missing images
    images.forEach(image => {
        new Image().src = image.src; // Preloading images
    });
    callChange(0, 0);
}, [callChange]);


return (
    <div className="carousel-background-container">
        <div className="carousel-container">
            <div className="carousel-title">
                <span className="upper-text">Endless options.</span>
                <span className="lower-text">Find your AI soulmate.</span>
                <span className="carousel-subtitle-2">She's right here, ready for a chat.  </span>
                <span className="carousel-subtitle-2">What are you waiting for? </span>
            </div>





            <Slider {...settings}>
                {images.map((image, index) => (
                    <CarouselItem key={image.id} image={image} index={index} currentIndex={currentIndex} />
                ))}

            </Slider>
        </div>
    </div>
);
};

export default CarouselPage;