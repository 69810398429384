import React, { useState } from 'react';
import './JoinAgainForm.css';
import SuccessScreen from './SuccessScreen';
const SupaKey = process.env.REACT_APP_SUPABASE_KEY;
const SupaBearerKey = `Bearer ${SupaKey}`;
const JoinAgainForm = () => {
    const [formData, setFormData] = useState({
        fullName: '', // Changed from "email" to "fullName"
        name: '',
        interests: ''
    });

    const [isSubmitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await fetch('https://cdgnyngcngxcykqrbmzy.supabase.co/functions/v1/beta-register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': SupaBearerKey
            },
            body: JSON.stringify({
                fullName: formData.fullName, // Changed from "email" to "fullName"
                name: formData.name,
                interests: formData.interests
            })
        });
        setSubmitted(true);
    };

    return (
        <div className="join-again-background">
            <div className="join-again-container">
                <h2 className="join-again-title">Join Beta</h2>
                <p className="join-again-description">Provide your information below:</p>
                {!isSubmitted ? (
                    <form onSubmit={handleSubmit}>
                        <div className="input-container">
                            <label htmlFor="fullName">Name:</label> {/* Changed label "Email" to "Full Name" */}
                            <input
                                type="text" // Changed type from "email" to "text"
                                id="fullName" // Changed from "email" to "fullName"
                                name="fullName" // Changed from "email" to "fullName"
                                className="input-field"
                                value={formData.fullName} // Changed from "formData.email" to "formData.fullName"
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-container">
                            <label htmlFor="name">Discord Username:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="input-field"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="textarea-container">
                            <label htmlFor="interests">Interests:</label>
                            <textarea
                                id="interests"
                                name="interests"
                                className="textarea-field"
                                value={formData.interests}
                                onChange={handleChange}
                                rows="5"
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="submit-button">Submit</button>
                    </form>
                ) : (
                    <SuccessScreen />
                )}
            </div>
        </div>
    );
};

export default JoinAgainForm;
