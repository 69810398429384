import './App.css';
import {BrowserRouter as Router, Route, Routes,} from "react-router-dom";
import {SalesPage} from "./SalesPage";


function App() {


    return (
        <div>
            <div id="modal-root"></div>
            <Router>
                <Routes>
                    <Route path="/" element={<SalesPage/>}/>
                </Routes>
            </Router>
        </div>
    );
}


export default App;