import React from 'react';
import { ReactComponent as ElyzaIcon } from './icons/ElyzaWhiteLogo.svg';
// import { ReactComponent as RedditIcon } from './icons/reddit.svg';
//
import { ReactComponent as DiscordIcon } from './icons/discord.svg';
//
import './Footer.css';
// import { Link } from 'react-router-dom';  // import the Link component from react-router-dom

const Footer = () => {
    const discordLink = 'https://discord.gg/WgWq3sua';

    // Handle the click event to open the link in a new tab
    const openLink = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div className="footer">
            <div className="footer-logo">
                <ElyzaIcon />
            </div>

            <div className="footer-information">
                <span className="footer-subtitle"> Questions? info@elyza.app </span>
                <span className="footer-subtitle"> Or contact us below </span>
                <div className="DiscordIconFooter" onClick={() => openLink(discordLink)}>
                    <DiscordIcon />
                </div>
            </div>


        </div>
    );
};

export default Footer;
