import React, { useState } from 'react';
import { ReactComponent as NavArrow } from "./icons/dropdown.svg";
import './FrequentQuestions.css';

const FrequentQuestions = () => {
    const [activeIndex, setActiveIndex] = useState(-1);

    const faqData = [
        {
            id: 1,
            question: 'Do I need to pay for the service?',
            answer: 'There is limited free access to the service. To unlock all features, you will need to purchase a subscription.',
        },
        {
            id: 2,
            question: 'How can I get started?',
            answer: 'To get started, simply sign up for an account, verify your age, and explore the various chats and character image creation features.',
        },
        {
            id: 3,
            question: 'Is my privacy protected?',
            answer: 'Yes, we take user privacy seriously. We use industry-standard encryption and data protection measures to safeguard your information. We are SOC 2 Type 2 certified.',
        },
        // {
        //     id: 4,
        //     question: 'What about NSFW content?',
        //     answer: 'We pride ourselves on providing a truly unfiltered experience. We do not censor any content, other than what is required by law.',
        // },
        {
            id: 5,
            question: 'How can I contact customer support?',
            answer: 'You can contact our customer support team through the "Contact Us" section on the website or by clicking here. We\'re here to assist you with any questions or concerns.',
        },
        // Add more FAQ items
    ];

    const toggleAnswer = (index) => {
        if (activeIndex === index) {
            setActiveIndex(-1); // Close the active item
        } else {
            setActiveIndex(index); // Open the clicked item
        }
    };


    return (
        <div className="faq-container">
            <div className="carousel-title">
                <span className="upper-text">We're here for you.</span>
                <span className="lower-text">Understand your AI soulmate.</span>
                <span className="carousel-subtitle-2">Got questions? Don’t understand something?</span>
                <span className="carousel-subtitle-2">We’ve got answers for you.</span>
            </div>
            <div className="faq-desktop">
            {faqData.map((item, index) => (
                <div
                    className={`faq-item ${index === activeIndex ? 'active' : ''}`}
                    key={item.id}
                    onClick={() => toggleAnswer(index)}
                >
                    <div className="faq-question">{item.question}</div>
                    <NavArrow className={`faq-toggle-icon ${index === activeIndex ? 'active' : ''}`} />
                    {index === activeIndex && <div className="faq-answer">{item.answer}</div>}
                </div>

            ))}
        </div>
        </div>
    );
};

export default FrequentQuestions;
